/* @import url('https://fonts.googleapis.com/css2?family=Bungee&family=Bungee+Outline&family=Poppins:ital,wght@0,200;0,300;0,400;0,500;0,800;0,900;1,100;1,200;1,300;1,400;1,700;1,800;1,900&family=Tangerine:wght@400;700&display=swap'); */

@import url('https://cdn.jsdelivr.net/npm/lightgallery@2.4.0/css/lightgallery.css');
@import url('https://cdn.jsdelivr.net/npm/lightgallery@2.4.0/css/lg-zoom.css');
@import url('https://cdn.jsdelivr.net/npm/lightgallery@2.4.0/css/lg-video.css');

@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,400;0,500;0,700;1,400&family=Tangerine:wght@400;700&display=swap');
@import url("./assets/css/master.css");

body {
  font-family: 'Poppins', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: "#f7f9f6";
}

code {
  font-family: 'Poppins', sans-serif;
  /* font-family: 'Tangerine', cursive; */
}